/*
 * Rules relating to parent-children associations in product configurator
 *
 * These rules are referenced several times in this config file
 * So we declare them outside the exported object
 */
const descendantRules = {
  product: {
    related_id: 'products',
    children: [],
  },
  productsection: {
    related_id: 'productsections',
    children: ['product', 'productsection', 'productspecstype'],
  },
  productspecstype: {
    edit_id_singular: 'ProductSpecstype',
    related_id: 'specstypes',
    children: ['product', 'productspecstype', 'productspecsvalue'],
    fields: [
      {
        key: 'title',
        label: 'generic.title',
        type: 'text',
        props: {
          disabled: true,
        }
      },
      {
        key: 'code',
        label: 'generic.code',
        type: 'text',
        props: {
          disabled: true,
        }
      },
      {
        key: 'use_exceptions',
        label: 'specstype.use_exceptions',
        type: 'boolean',
      },
      {
        key: 'is_multiple',
        label: 'specstype.is_multiple',
        type: 'boolean',
      },
      {
        key: 'is_optional',
        label: 'specstype.is_optional',
        type: 'boolean',
      },
      {
        key: 'display_configurator',
        label: 'specstype.display_configurator',
        type: 'boolean',
      },
      {
        key: 'specstype',
        label: 'specstype.specstype',
        type: 'select',
        related_key: 'specstypes',
        related: 'specstype { id, title }',
        related_table: 'specstypes',
        related_id: 'specstype_id',
        listing_attribute: 'title',
        listing_attributes: 'id,title',
        related_fields: [
          'title',
          'id'
        ],
        props: {
          link: true,
        }
      },
    ]
  },
  productspecsvalue: {
    edit_id_singular: 'ProductSpecsvalue',
    related_id: 'specsvalues',
    children: [],
    fields: [
      {
        key: 'title',
        label: 'generic.title',
        type: 'text',
        props: {
          disabled: true,
        }
      },
      {
        key: 'code',
        label: 'generic.code',
        type: 'text',
        props: {
          disabled: true,
        }
      },
      {
        key: 'use_exceptions',
        label: 'specsvalue.use_exceptions',
        type: 'boolean',
      },
      {
        key: 'increment',
        label: 'specsvalue.increment',
        type: 'number',
      },
      {
        key: 'specsvalue',
        label: 'specsvalue.specsvalue',
        type: 'select',
        related_key: 'specsvalues',
        related: 'specsvalue { id, title }',
        related_table: 'specsvalues',
        related_id: 'specsvalue_id',
        listing_attribute: 'title',
        listing_attributes: 'id,title',
        related_fields: [
          'title',
          'id'
        ],
        props: {
          link: true,
        }
      },
    ]
  },
  defaultAddType: 'productsection',
};

export default {
  id: 'products',
  idSingular: 'product',
  saveable: true,
  publishingTools: false,
  typeName: 'product',
  label: 'product.products',
  label_details: 'product.product',
  verbose_label_singular: 'product.verbose_product',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'product.products',
    icon: 'precision_manufacturing',
  },
  submodules: [
    {
      id: 'products.specstypes',
      title: 'specstype.specstypes',
      path: '/specstypes'
    },
    {
      id: 'products.specsvalues',
      title: 'specsvalue.specsvalues',
      path: '/specsvalues'
    },
    {
      id: 'products.productsections',
      title: 'productsection.productsections',
      path: '/productsections'
    },
    {
      id: 'products.rules',
      title: 'rule.rules',
      path: '/rules'
    },
  ],
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'categories',
      label: 'category.categories',
      hashLink: 'categories',
      panel: true,
    },
    {
      key: 'configurator',
      label: 'product.configurator',
      hashLink: 'configurator',
      panel: true,
    },
    {
      key: 'documents',
      label: 'product.documents',
      hashLink: 'documents',
      panel: true,
    },
    {
      key: 'products',
      label: 'product.related_products',
      hashLink: 'related_products',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        edit: true,
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        add: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'subtitle',
      groupkey: 'main',
      label: 'generic.subtitle',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      search: true,
      international: true,
    },
    {
      key: 'link',
      groupkey: 'main',
      label: 'generic.link',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      props: {
        rules: [
          value => (value === '' || value === null) || value && value.length <= 300 || 'errors.max_300',
          value => {
            const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
            return (value === '' || value === null) || value && pattern.test(value) || 'errors.link_wrong_format';
          },
        ]
      },
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'product.code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      counter: 20,
    },
    {
      key: 'meta_description',
      groupkey: 'main',
      label: 'product.meta_description',
      type: 'textarea',
      international: true,
      display: {
        add: true,
        edit: true,
      },
      props: {
        counter: 140,
      },
    },
    {
      key: 'description',
      groupkey: 'main',
      label: 'generic.description',
      type: 'htmltexteditor',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      international: true,
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'main',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'image_rollover',
      label: 'product.image_rollover',
      type: 'media',
      groupkey: 'main',
      related: 'image_rollover { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        add: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'image_slides',
      label: 'Images',
      type: 'media',
      groupkey: 'documents',
      related: 'image_slides { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        multiple: true,
        typeFilter: ['image/%']
      },
    },
    {
      key: 'medias',
      groupkey: 'documents',
      label: 'product.documents',
      type: 'relations',
      related: 'medias { id, title, filename, file, type, mediacategories { id, title }, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'medias',
      idSingular: 'media',
      related_fields: 'id, filename',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          type: 'multiple',
          whereClause: 'type[like]',
          clauseSeparator: '(||)',
          values: [
            'application/pdf',
            'application/zip',
          ],
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
          disabledDelete: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'filename',
            label: 'generic.filename',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'mediacategories',
            label: 'category.categories',
            type: 'select',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'categories',
      groupkey: 'categories',
      label: 'category.categories',
      type: 'relations',
      related: 'categories { id, title, type { id, title } }',
      related_id: 'categories',
      idSingular: 'category',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'type',
            label: 'categorytype.categorytype',
            type: 'select',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
          addRelation: true,
        },
      },
    },
    {
      key: 'products',
      groupkey: 'products',
      label: 'product.related_products',
      type: 'relations',
      related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'products',
      idSingular: 'product',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          type: 'single',
          whereClause: 'id[!=]',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'productconfigurator',
      type: 'relations',
      groupkey: 'configurator',
      label: 'product.configurator',
      related: `productconfigurator {
        id
        uniqueId: id
        title
        code
        item_module
        item_id
        entity_id
        parent_module
        parent_id
        rank
        children: productconfigurator {
          id
          uniqueId: id
          title
          code
          item_module
          item_id
          entity_id
          parent_module
          parent_id
          rank
          children: productconfigurator {
            id
            uniqueId: id
            title
            code
            item_module
            item_id
            entity_id
            parent_module
            parent_id
            rank
            children: productconfigurator {
              id
              uniqueId: id
              title
              code
              item_module
              item_id
              entity_id
              parent_module
              parent_id
              rank
            }
          }
        }
      }`,
      related_id: 'productsections',
      idSingular: 'productconfigurator',
      parent_id: 'parent_id',
      select_id: 'entity_id',
      clickItemRefetch: false,
      col: 'col-12',
      display: {
        edit: true
      },
      actions: {
        selectRows: true,
        individualAdd: true,
      },
      addFields: [
        {
          key: 'item_id',
          type: 'number',
        },
        {
          key: 'parent_id',
          type: 'number',
        },
        {
          key: 'item_module',
          type: 'text',
        },
        {
          key: 'parent_module',
          type: 'text',
        },
      ],
      filters: [
        {
          id: 'id',
          type: 'multiple',
          whereClause: 'id[!=]',
          filterBy: 'entity_id',
        },
        {
          parentModule: 'productspecstype',
          childModule: 'specsvalues',
          select: 'entity_id',
          type: 'singleRelated',
          whereClause: 'specstype_id',
        },
        {
          parentModule: 'productspecstype',
          childModule: 'specstypes',
          select: 'entity_id',
          type: 'singleRelated',
          whereClause: 'id[!=]',
        },
        {
          typeName: 'products',
          select: 'id',
          type: 'single',
          whereClause: 'id[!=]',
        },
      ],
      descendantRules, // Declared at the beginning of this config file
      props: {
        table: false,
        treeview: true,
        treeviewProps: {
          openAll: true,
          draggable: true,
          draggableMaxLevel: 4,
          actionsMaxLevel: 4,
          draggableRules: [
            ({ item, target }) => {
              if (!target) {
                return false;
              }
              // Specvalues can only be dragged to the same parent spectype
              if (item.item_module === 'productspecsvalue') {
                return item.parent_id === target.id;
              } else {
                return descendantRules[target.item_module] !== undefined
                  && descendantRules[target.item_module].children.includes(item.item_module);
              }
            }
          ]
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'code',
            label: 'generic.code',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'productconfigurator',
            type: 'children',
            label: 'product.configurator',
            related: 'productconfigurator { id title code item_module item_id parent_module parent_id rank }',
            related_id: 'productconfigurators',
            idSingular: 'productconfigurator',
            parent_id: 'parent_id',
          }
        ],
        actions: {
          selectRows: true,
          deleteRows: true,
          editRows: true,
          editPage: ['product', 'productsection'],
          editModal: ['productspecsvalue', 'productspecstype']
        }
      }
    },

    {
      key: 'rules',
      groupkey: 'configurator',
      label: 'rule.rules',
      type: 'relations',
      related: 'rules { id, left_code, symbol, right_code }',
      related_id: 'rules',
      related_fields: 'id, left_code, symbol, right_code',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'left_code',
            label: 'rule.left_code',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'symbol',
            label: 'rule.symbol',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'right_code',
            label: 'rule.right_code',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
          addRelation: true,
        },
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
