export default {
  award: 'Récompense',
  awards: 'Récompenses',
  verbose_award: 'une récompense',
  content: 'Contenu',
  event_date: 'Date d\'évènement',
  published_at: 'Date de publication',
  messages: {
    award_not_found: 'Récompense non trouvée',
  },
};
