export default {
  global: {
    primary: '#165e57',
    accent: '#de8800',
    background: '#F6F6F6',
    border: '#cbcbcb',
    iconFont: 'mdi',
    favicon: 'favicon.png',
  },
  nav: {
    primary: '#165e57',
    secondary: '#10413c',
  },
  login: {
    logo: 'logo.jpg',
    logoEmail: 'logo-email.jpg',
    splash: 'splash.png',
  },
};
