import * as slug from 'slugify';
export default {
  id: 'awards',
  idSingular: 'award',
  saveable: true,
  typeName: 'award',
  label: 'award.awards',
  label_details: 'award.awards',
  verbose_label_singular: 'award.verbose_award',
  itemBreadcrumb: 'title',
  i18n: true,
  filters: [
    {
      id: 'articletype',
      type: 'select',
      whereClause: 'articletype_id',
      label: 'articletype.select_articletype',
    },
    {
      id: 'dates',
      type: 'daterange',
      label: 'award.published_at',
      whereClause: [
        'published_at[>=]',
        'published_at[<=]',
      ],
    },
  ],
  previewUrl: (item, i18n) => `${process.env.VUE_APP_PROJECT_URL}/${i18n('award.front_path')}/${slug(item.title, { replacement: '-', lower: true })}-${item.id}?cmspreview=true`,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'images',
      label: 'Images',
      hashLink: 'images',
      panel: true,
    },
    {
      key: 'content',
      label: 'award.content',
      hashLink: 'content',
      panel: true,
    },
    {
      key: 'articles',
      label: 'article.articles',
      hashLink: 'articles',
      panel: true,
    },
    {
      key: 'projects',
      label: 'project.projects',
      hashLink: 'projects',
      panel: true,
    },
    {
      key: 'products',
      label: 'product.products',
      hashLink: 'products',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
        edit: true,
      },
      search: true,
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        add: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'articletype',
      groupkey: 'main',
      label: 'articletype.articletype',
      type: 'select',
      related_key: 'articletypes',
      related: 'articletype { id, title }',
      related_table: 'articletypes',
      related_id: 'articletype_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      }
    },
    {
      key: 'description',
      groupkey: 'main',
      label: 'generic.description',
      type: 'textarea',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
      props: {
        counter: 140,
      },
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'images',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        listing: true,
      },
      props: {
        typeFilter: ['image/%']
      },
      notsortable: true,
    },
    {
      key: 'event_date',
      groupkey: 'main',
      label: 'award.event_date',
      type: 'date',
      display: {
        add: true,
        edit: true,
      },
      required: true,
    },
    {
      key: 'published_at',
      groupkey: 'main',
      label: 'article.published_at',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
    },
    {
      key: 'image_slides',
      label: 'Slides',
      type: 'media',
      groupkey: 'images',
      related: 'image_slides { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        multiple: true,
        typeFilter: ['image/%']
      },
    },
    {
      key: 'articles',
      groupkey: 'articles',
      label: 'article.related_articles',
      type: 'relations',
      related: 'articles { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'articles',
      idSingular: 'article',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          whereClause: 'id',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'projects',
      groupkey: 'projects',
      label: 'project.related_projects',
      type: 'relations',
      related: 'projects { id, title, place, year, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'projects',
      idSingular: 'project',
      related_fields: 'id, title, place, year',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          whereClause: 'id',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'place',
            label: 'project.place',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'year',
            label: 'project.year',
            type: 'number',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'products',
      groupkey: 'products',
      label: 'product.related_products',
      type: 'relations',
      related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'products',
      idSingular: 'product',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'blocks',
      groupkey: 'content',
      type: 'relations',
      label: 'column.blocks',
      related: 'blocks { id, status, title, parent_id }',
      related_id: 'blocks',
      idSingular: 'block',
      parent_id: 'parent_id',
      parent_module: 'award',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'parent_id',
            type: 'number',
          },
          {
            key: 'parent_module',
            type: 'text',
          },
          {
            key: 'title',
            type: 'text',
            label: 'page.internal_title',
            international: true,
            required: true,
            display: {
              edit: true,
              add: true,
              table: true,
            },
          },
          {
            key: 'status',
            type: 'status',
            label: 'generic.state',
            display: {
              table: true,
              listing: true,
            },
          },
          {
            key: 'position',
            type: 'btngroup',
            label: 'block.position',
            display: {
              edit: true,
              add: true,
            },
            props: {
              defaultValue: 4,
              columns: 3,
              icons: [
                'north_west',
                'north',
                'north_east',
                'west',
                'center_focus_strong',
                'east',
                'south_west',
                'south',
                'south_east',
              ],
            },
          },
          {
            key: 'bk_color',
            type: 'colorpicker',
            label: 'block.bk_color',
            display: {
              edit: true,
              add: true,
            },
            props: {
              defaultValue: '#FFFFFFFF',
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              edit: true,
              add: true,
            },
            international: true,
            props: {
              typeFilter: ['image/%']
            },
          },
          {
            key: 'video',
            label: 'generic.video',
            type: 'media',
            groupkey: 'main',
            related: 'video { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              edit: true,
              add: true,
            },
            international: true,
            props: {
              typeFilter: ['video/%']
            },
          },
          {
            key: 'href',
            type: 'text',
            label: 'block.href',
            international: true,
            display: {
              edit: true,
              add: true,
            },
            props: {
              onFocusDefaultValue: 'http://',
              rules: [
                value => value === null || value === '' || (value && value.length <= 300) || 'errors.max_300',
                value => {
                  const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
                  return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
                },
              ]
            },
          },
          {
            key: 'targetblank',
            type: 'boolean',
            label: 'block.targetblank',
            display: {
              edit: true,
              add: true,
            },
          },
          {
            key: 'fit_height_to_bk_image',
            type: 'boolean',
            label: 'block.fit_height_to_bk_image',
            display: {
              edit: true,
              add: true,
            },
          },
          {
            key: 'contentblocks',
            label: 'contentblock.contentblocks',
            related: 'contentblocks { id, status, content_type, content, title, block_id }',
            related_id: 'contentblocks',
            idSingular: 'contentblock',
            idSingularParent: 'block',
            related_fields: 'id, content_type, content, title, block_id',
            parent_id: 'block_id',
            type: 'relations',
            preselectValue: 'content_type',
            display: {
              edit: true,
            },
            col: 'col-12', // For all width of the page
            props: {
              table: true,
              tableProps: {
                rankDrag: true,
                hideDefaultFooter: true,
                disablePagination: true,
                disableSort: true,
              },
              relatedFields: [
                {
                  key: 'id',
                  type: 'number',
                },
                {
                  key: 'block_id',
                  type: 'number',
                },
                {
                  key: 'status',
                  type: 'status',
                  label: 'generic.state',
                  display: {
                    table: true,
                  },
                },
                {
                  key: 'content_type',
                  label: 'block.content_type',
                  type: 'select',
                  display: {
                    edit: true,
                    add: true,
                    table: true,
                  },
                  required: true,
                  items: [
                    {
                      title: 'block.html',
                      id: 'html',
                    },
                    {
                      title: 'block.image',
                      id: 'image',
                    },
                    {
                      title: 'block.video',
                      id: 'video',
                    },
                    {
                      title: 'block.button',
                      id: 'button',
                    },
                  ],
                },
                {
                  key: 'title',
                  label: 'page.internal_title',
                  type: 'text',
                  required: true,
                  international: true,
                  display: {
                    edit: ['image', 'video', 'html', 'button'],
                    add: ['image', 'video', 'html', 'button'],
                    table: true,
                  }
                },
                {
                  key: 'content',
                  label: 'block.content',
                  type: 'htmltexteditor',
                  international: true,
                  required: true,
                  toolbar: // If the field is a WYSIWYG, we can override the default toolbar options
                    `undo redo | formatselect | bold italic underline |
                    bullist numlist | link | removeformat | code`, // Default toolbar values
                  display: {
                    edit: ['html'],
                    add: ['html'],
                    table: true,
                  },
                },
                {
                  key: 'content',
                  label: 'block.content',
                  type: 'text',
                  international: true,
                  required: true,
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                },
                {
                  key: 'image',
                  label: 'generic.image',
                  type: 'media',
                  groupkey: 'main',
                  related: 'image { id, filename, file, type, dimensions, weight }',
                  listing_attribute: 'file',
                  listing_attributes: 'id,file',
                  display: {
                    edit: ['image'],
                    add: ['image'],
                  },
                  props: {
                    typeFilter: ['image/%']
                  },
                },
                {
                  key: 'video',
                  label: 'generic.video',
                  type: 'media',
                  groupkey: 'main',
                  related: 'video { id, filename, file, type, dimensions, weight }',
                  listing_attribute: 'file',
                  listing_attributes: 'id,file',
                  display: {
                    edit: ['video'],
                    add: ['video'],
                  },
                  props: {
                    typeFilter: ['video/%']
                  },
                },
                {
                  key: 'bk_color',
                  type: 'colorpicker',
                  label: 'block.bk_color',
                  display: {
                    edit: ['html'],
                    add: ['html'],
                  },
                },
                {
                  key: 'bk_color',
                  type: 'colorpicker',
                  label: 'block.bk_color',
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                  props: {
                    defaultValue: '#D44C4FFF',
                  },
                },
                {
                  key: 'border_color',
                  type: 'colorpicker',
                  label: 'block.border_color',
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                  props: {
                    defaultValue: '#D44C4FFF',
                  },
                },
                {
                  key: 'content_color',
                  type: 'colorpicker',
                  label: 'block.text_color',
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                  props: {
                    defaultValue: '#FFFFFFFF',
                  },
                },
                {
                  key: 'content_color',
                  type: 'colorpicker',
                  label: 'block.text_color',
                  display: {
                    edit: ['html'],
                    add: ['html'],
                  },
                  props: {
                    defaultValue: '#000000FF',
                  },
                },
                {
                  key: 'content_align',
                  type: 'btngroup',
                  label: 'block.text_align',
                  display: {
                    edit: ['html', 'button'],
                    add: ['html', 'button'],
                  },
                  props: {
                    defaultValue: 0,
                    columns: 3,
                    icons: [
                      'format_align_left',
                      'format_align_justify',
                      'format_align_right',
                    ],
                  },
                },
                {
                  key: 'href',
                  type: 'text',
                  label: 'block.href',
                  international: true,
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                  props: {
                    onFocusDefaultValue: 'http://',
                    rules: [
                      value => value === null || value === '' || (value && value.length <= 300) || 'errors.max_300',
                      value => {
                        const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
                        return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
                      },
                    ]
                  },
                },
                {
                  key: 'targetblank',
                  type: 'boolean',
                  label: 'block.targetblank',
                  display: {
                    edit: ['button'],
                    add: ['button'],
                  },
                },
              ],
              actions: {
                editRows: true,
                deleteRows: true,
                addRows: true,
              },
            },
          },
        ],
        actions: {
          editRows: true,
          deleteRows: true,
          addRows: true,
        },
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
