export default {
  id: 'specsvalues',
  idSingular: 'specsvalue',
  saveable: true,
  typeName: 'specsvalue',
  label: 'specsvalue.specsvalues',
  label_details: 'specsvalue.specsvalue',
  verbose_label_singular: 'specsvalue.verbose_specsvalue',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'specstypes',
      type: 'select',
      whereClause: 'specstype_id',
      label: 'specstype.select_specstypes',
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'short_title',
      groupkey: 'main',
      label: 'specsvalue.short_title',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      search: true,
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'increment',
      groupkey: 'main',
      label: 'specsvalue.increment',
      type: 'number',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'main',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'internal_notes',
      groupkey: 'main',
      label: 'generic.internal_notes',
      type: 'textarea',
      display: {
        edit: true,
        add: true,
      },
      search: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
