import * as slug from 'slugify';
export default {
    id: 'projects',
    idSingular: 'project',
    typeName: 'project',
    saveable: true,
    label: 'project.projects',
    label_details: 'project.project',
    verbose_label_singular: 'project.verbose_project',
    itemBreadcrumb: 'title',
    i18n: true,
    previewUrl: (item, i18n) => `${process.env.VUE_APP_PROJECT_URL}/${i18n('project.front_path')}/${slug(item.title, { replacement: '-', lower: true })}-${item.id}?cmspreview=true`,
    menu: {
      title: 'project.projects',
      icon: 'folder',
    },
    submodules: [
      {
        id: 'projects.participants',
        title: 'participant.participants',
        path: '/participants'
      },
      {
        id: 'projects.participanttypes',
        title: 'participanttype.participanttypes',
        path: '/participanttypes'
      },
    ],
    groupFields: [
      {
        key: 'main',
        label: 'generic.general_info',
        hashLink: 'general-info',
        panel: true,
      },
      {
        key: 'images',
        label: 'project.images',
        hashLink: 'images',
        panel: true,
      },
      {
        key: 'content',
        label: 'project.content',
        hashLink: 'content',
        panel: true,
      },
      {
        key: 'categories',
        label: 'category.categories',
        hashLink: 'categories',
        panel: true,
      },
      {
        key: 'projects',
        label: 'project.related_projects',
        hashLink: 'related_projects',
        panel: true,
      },
      {
        key: 'products',
        label: 'product.related_products',
        hashLink: 'products',
        panel: true,
      },
      {
        key: 'participants',
        label: 'participant.participants',
        hashLink: 'participants',
        panel: true,
      },
      {
        key: 'awards',
        label: 'award.awards',
        hashLink: 'awards',
        panel: true,
      },
    ],
    fields: [
      {
        key: 'id',
        groupkey: 'main',
        label: 'ID',
        type: 'text',
        fetch: {
          listing: true,
        },
      },
      {
        key: 'status',
        type: 'status',
        label: 'generic.state',
        display: {
          edit: true,
          add: true,
          listing: true,
        },
        listingStyle: {
          columnWidth: 80,
        },
      },
      {
        key: 'title',
        groupkey: 'main',
        label: 'generic.title',
        type: 'text',
        required: true,
        international: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        search: true,
      },
      {
        key: 'meta_description',
        groupkey: 'main',
        label: 'project.meta_description',
        type: 'textarea',
        international: true,
        display: {
          add: true,
          edit: true,
        },
        search: true,
        props: {
          counter: 140,
        },
      },
      {
        key: 'description',
        groupkey: 'main',
        label: 'project.description',
        type: 'htmltexteditor',
        required: true,
        international: true,
        display: {
          edit: true,
          add: true,
        },
        search: true,
      },
      {
        key: 'place',
        groupkey: 'main',
        label: 'project.place',
        type: 'text',
        required: true,
        international: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        props: {
          hint: 'Format: MANHATTAN, NY, USA',
        },
        search: true,
      },
      {
        key: 'year',
        groupkey: 'main',
        label: 'project.year',
        type: 'number',
        required: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        props: {
          counter: 4,
          rules: [
              value => value && value > -1 && value < 10000
              || 'project.validation.year',
          ],
        },
        search: true,
      },
      {
        key: 'image',
        groupkey: 'images',
        label: 'generic.image',
        type: 'media',
        related: 'image { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        international: true,
        props: {
          typeFilter: ['image/%']
        },
      },
      {
        key: 'image_rollover',
        groupkey: 'images',
        label: 'project.image_rollover',
        type: 'media',
        related: 'image_rollover { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        display: {
          edit: true,
          add: true,
        },
        international: true,
        props: {
          typeFilter: ['image/%']
        },
      },
      {
        key: 'image_slides',
        groupkey: 'images',
        label: 'project.image_slides',
        type: 'media',
        related: 'image_slides { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          multiple: true,
          typeFilter: ['image/%']
        },
      },
      {
        key: 'categories',
        groupkey: 'categories',
        label: 'category.categories',
        type: 'relations',
        related: 'categories { id, title, type { id, title } }',
        related_id: 'categories',
        idSingular: 'category',
        related_fields: 'id, title',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'type',
              label: 'categorytype.categorytype',
              type: 'select',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'projects',
        groupkey: 'projects',
        label: 'project.related_projects',
        type: 'relations',
        related: 'projects { id, title, place, year, image { id, filename, file, type, dimensions, weight } }',
        related_id: 'projects',
        idSingular: 'project',
        related_fields: 'id, title, place, year',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        filters: [
          {
            id: 'id',
            select: 'id',
            type: 'single',
            whereClause: 'id[!=]',
          }
        ],
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'place',
              label: 'project.place',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'year',
              label: 'project.year',
              type: 'number',
              display: {
                table: true,
              },
            },
            {
              key: 'image',
              label: 'generic.image',
              type: 'media',
              related: 'image { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'products',
        groupkey: 'products',
        label: 'product.related_products',
        type: 'relations',
        related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
        related_id: 'products',
        idSingular: 'product',
        related_fields: 'id, title, status',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'status',
              label: 'generic.state',
              type: 'status',
              display: {
                table: true,
              },
            },
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'image',
              label: 'generic.image',
              type: 'media',
              groupkey: 'main',
              related: 'image { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                table: true,
              },
              props: {
                typeFilter: ['image/%']
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'participants',
        groupkey: 'participants',
        label: 'participant.participants',
        type: 'relations',
        related: 'participants { id, name, participanttype { id, title }, link, email }',
        related_id: 'participants',
        idSingular: 'participant',
        related_fields: 'id, name, link, email',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        filters: [
          {
            id: 'id',
            select: 'id',
            whereClause: 'id',
          }
        ],
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'name',
              label: 'generic.name',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'participanttype',
              label: 'participanttype.participanttype',
              type: 'select',
              display: {
                table: true,
              },
            },
            {
              key: 'link',
              label: 'generic.link',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'email',
              label: 'generic.email',
              type: 'email',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'awards',
        groupkey: 'awards',
        label: 'award.awards',
        type: 'relations',
        related: 'awards { id, title }',
        related_id: 'awards',
        idSingular: 'award',
        related_fields: 'id, title',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            }
          ],
          actions: {
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'sections',
        type: 'relations',
        groupkey: 'content',
        label: 'page.sections',
        related: `sections {
          id, status, title, parent_id, uniqueId, children: rows {
            id, status, title, section_id, uniqueId, children: columns {
              id, status, title, row_id, uniqueId, children: blocks {
                id, status, title, parent_id, uniqueId
              }
            }
          }
        }`,
        related_id: 'sections',
        idSingular: 'section',
        parent_module: 'project',
        parent_id: 'parent_id',
        col: 'col-12',
        display: {
          edit: true
        },
        props: {
          table: false,
          treeview: true,
          treeviewProps: {
            openAll: true,
            draggable: true,
            draggableMaxLevel: 4,
            draggableRules: [
              ({ itemDepth, targetDepth }) => itemDepth - 1 === targetDepth,
            ]
          },
          relatedFields: [
            {
              key: 'parent_id',
              type: 'number',
            },
            {
              key: 'parent_module',
              type: 'text',
            },
            {
              key: 'title',
              type: 'text',
              label: 'page.internal_title',
              required: true,
              international: true,
              display: {
                edit: true,
                add: true,
                table: true,
                treeview: true,
              },
            },
            // {
            //   key: 'padding',
            //   type: 'spacing',
            //   label: 'generic.padding',
            //   display: {
            //     edit: true,
            //     add: true,
            //   },
            // },
            // {
            //   key: 'margin',
            //   type: 'spacing',
            //   label: 'generic.margin',
            //   display: {
            //     edit: true,
            //     add: true,
            //   },
            // },
            {
              key: 'color',
              type: 'colorpicker',
              label: 'generic.color',
              display: {
                edit: true,
                add: true,
              },
              props: {
                defaultValue: '#FFFFFFFF'
              }
            },
            {
              key: 'image',
              label: 'generic.image',
              type: 'media',
              groupkey: 'main',
              related: 'image { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                edit: true,
                add: true,
              },
              international: true,
              props: {
                typeFilter: ['image/%']
              },
            },
            {
              key: 'video',
              label: 'generic.video',
              type: 'media',
              groupkey: 'main',
              related: 'video { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                edit: true,
                add: true,
              },
              international: true,
              props: {
                typeFilter: ['video/%']
              },
            },
            {
              key: 'status',
              type: 'status',
              label: 'section.status',
              display: {
                treeview: true,
              },
            },
            {
              key: 'rows',
              type: 'children',
              label: 'section.rows',
              related: 'rows { id, status, title, section_id }',
              related_id: 'rows',
              idSingular: 'row',
              parent_id: 'section_id',
              relatedFields: [
                {
                  key: 'section_id',
                  type: 'number',
                },
                {
                  key: 'title',
                  type: 'text',
                  label: 'page.internal_title',
                  international: true,
                  required: true,
                  display: {
                    edit: true,
                    add: true,
                    treeview: true,
                  },
                },
                {
                  key: 'layout',
                  type: 'select',
                  label: 'row.layout',
                  required: true,
                  items: [
                    {
                      title: '1/1',
                      id: '1/1',
                    },
                    {
                      title: '1/2',
                      id: '1/2',
                    },
                    {
                      title: '1/3',
                      id: '1/3',
                    },
                    {
                      title: '1/4',
                      id: '1/4',
                    },
                    {
                      title: '1/3-2/3',
                      id: '1/3-2/3',
                    },
                    {
                      title: '2/3-1/3',
                      id: '2/3-1/3',
                    },
                  ],
                  display: {
                    edit: true,
                    add: true,
                  },
                },
                {
                  key: 'image',
                  label: 'generic.image',
                  type: 'media',
                  groupkey: 'main',
                  related: 'image { id, filename, file, type, dimensions, weight }',
                  listing_attribute: 'file',
                  listing_attributes: 'id,file',
                  display: {
                    edit: true,
                    add: true,
                  },
                  international: true,
                  props: {
                    typeFilter: ['image/%']
                  },
                },
                {
                  key: 'video',
                  label: 'generic.video',
                  type: 'media',
                  groupkey: 'main',
                  related: 'video { id, filename, file, type, dimensions, weight }',
                  listing_attribute: 'file',
                  listing_attributes: 'id,file',
                  display: {
                    edit: true,
                    add: true,
                  },
                  international: true,
                  props: {
                    typeFilter: ['video/%']
                  },
                },
                // {
                //   key: 'padding',
                //   type: 'spacing',
                //   label: 'generic.padding',
                //   display: {
                //     edit: true,
                //     add: true,
                //   },
                // },
                // {
                //   key: 'margin',
                //   type: 'spacing',
                //   label: 'generic.margin',
                //   display: {
                //     edit: true,
                //     add: true,
                //   },
                // },
                {
                  key: 'color',
                  type: 'colorpicker',
                  label: 'generic.color',
                  display: {
                    edit: true,
                    add: true,
                  },
                  props: {
                    defaultValue: '#FFFFFFFF'
                  }
                },
                {
                  key: 'gutters',
                  type: 'boolean',
                  label: 'row.gutters',
                  display: {
                    edit: true,
                    add: true,
                  },
                },
                {
                  key: 'contained',
                  type: 'boolean',
                  label: 'row.contained',
                  display: {
                    edit: true,
                    add: true,
                  },
                },
                {
                  key: 'status',
                  type: 'status',
                  label: 'generic.state',
                  display: {
                    treeview: true,
                    listing: true,
                  },
                },
                {
                  key: 'columns',
                  type: 'children',
                  label: 'row.columns',
                  related: 'columns { id, status, title, row_id }',
                  related_id: 'columns',
                  idSingular: 'column',
                  parent_id: 'row_id',
                  relatedFields: [
                    {
                      key: 'row_id',
                      type: 'number',
                    },
                    {
                      key: 'title',
                      type: 'text',
                      label: 'page.internal_title',
                      international: true,
                      required: true,
                      display: {
                        edit: true,
                        add: true,
                        treeview: true,
                      },
                    },
                    {
                      key: 'slide_per_view',
                      type: 'number',
                      label: 'column.slide_per_view',
                      required: true,
                      display: {
                        edit: true,
                        add: true,
                      },
                      props: {
                        defaultValue: 1,
                      },
                    },
                    {
                      key: 'slide_per_group',
                      type: 'number',
                      label: 'column.slide_per_group',
                      required: true,
                      display: {
                        edit: true,
                        add: true,
                      },
                      props: {
                        defaultValue: 1,
                      },
                    },
                    {
                      key: 'loop_circuit',
                      type: 'boolean',
                      label: 'column.loop_circuit',
                      display: {
                        edit: true,
                        add: true,
                      },
                    },
                    // {
                    //   key: 'auto_height',
                    //   type: 'boolean',
                    //   label: 'column.auto_height',
                    //   display: {
                    //     edit: true,
                    //     add: true,
                    //   },
                    // },
                    {
                      key: 'auto_play',
                      type: 'boolean',
                      label: 'column.auto_play',
                      display: {
                        edit: true,
                        add: true,
                      },
                    },
                    {
                      key: 'status',
                      type: 'status',
                      label: 'generic.state',
                      display: {
                        treeview: true,
                        listing: true,
                      },
                    },
                    {
                      key: 'blocks',
                      type: 'children',
                      label: 'column.blocks',
                      related: 'blocks { id, status, title, parent_id }',
                      related_id: 'blocks',
                      idSingular: 'block',
                      parent_id: 'parent_id',
                      parent_module: 'column',
                      relatedFields: [
                        {
                          key: 'parent_id',
                          type: 'number',
                        },
                        {
                          key: 'parent_module',
                          type: 'text',
                        },
                        {
                          key: 'title',
                          type: 'text',
                          label: 'page.internal_title',
                          international: true,
                          required: true,
                          display: {
                            edit: true,
                            add: true,
                            treeview: true,
                          },
                        },
                        {
                          key: 'status',
                          type: 'status',
                          label: 'generic.state',
                          display: {
                            treeview: true,
                            listing: true,
                          },
                        },
                        {
                          key: 'position',
                          type: 'btngroup',
                          label: 'block.position',
                          display: {
                            edit: true,
                            add: true,
                          },
                          props: {
                            defaultValue: 4,
                            columns: 3,
                            icons: [
                              'north_west',
                              'north',
                              'north_east',
                              'west',
                              'center_focus_strong',
                              'east',
                              'south_west',
                              'south',
                              'south_east',
                            ],
                          },
                        },
                        // {
                        //   key: 'text_align',
                        //   type: 'btngroup',
                        //   label: 'block.text_align',
                        //   display: {
                        //     edit: true,
                        //     add: true,
                        //   },
                        //   props: {
                        //     defaultValue: 0,
                        //     columns: 3,
                        //     icons: [
                        //       'format_align_left',
                        //       'format_align_justify',
                        //       'format_align_right',
                        //     ],
                        //   },
                        // },
                        // {
                        //   key: 'height',
                        //   type: 'number',
                        //   label: 'block.min_height',
                        //   display: {
                        //     edit: true,
                        //     add: true,
                        //   },
                        // },
                        // {
                        //   key: 'width',
                        //   type: 'number',
                        //   label: 'block.max_width',
                        //   display: {
                        //     edit: true,
                        //     add: true,
                        //   },
                        // },
                        {
                          key: 'bk_color',
                          type: 'colorpicker',
                          label: 'block.bk_color',
                          display: {
                            edit: true,
                            add: true,
                          },
                          props: {
                            defaultValue: '#FFFFFFFF',
                          },
                        },
                        // {
                        //   key: 'padding',
                        //   type: 'spacing',
                        //   label: 'generic.padding',
                        //   display: {
                        //     edit: true,
                        //     add: true,
                        //   },
                        // },
                        {
                          key: 'image',
                          label: 'generic.image',
                          type: 'media',
                          groupkey: 'main',
                          related: 'image { id, filename, file, type, dimensions, weight }',
                          listing_attribute: 'file',
                          listing_attributes: 'id,file',
                          display: {
                            edit: true,
                            add: true,
                          },
                          international: true,
                          props: {
                            typeFilter: ['image/%']
                          },
                        },
                        {
                          key: 'video',
                          label: 'generic.video',
                          type: 'media',
                          groupkey: 'main',
                          related: 'video { id, filename, file, type, dimensions, weight }',
                          listing_attribute: 'file',
                          listing_attributes: 'id,file',
                          display: {
                            edit: true,
                            add: true,
                          },
                          international: true,
                          props: {
                            typeFilter: ['video/%']
                          },
                        },
                        {
                          key: 'href',
                          type: 'text',
                          label: 'block.href',
                          international: true,
                          display: {
                            edit: true,
                            add: true,
                          },
                          props: {
                            onFocusDefaultValue: 'http://',
                            rules: [
                              value => value === null || value === '' || (value && value.length <= 300) || 'errors.max_300',
                              value => {
                                const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
                                return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
                              },
                            ]
                          },
                        },
                        {
                          key: 'targetblank',
                          type: 'boolean',
                          label: 'block.targetblank',
                          display: {
                            edit: true,
                            add: true,
                          },
                        },
                        {
                          key: 'fit_height_to_bk_image',
                          type: 'boolean',
                          label: 'block.fit_height_to_bk_image',
                          display: {
                            edit: true,
                            add: true,
                          },
                        },
                        {
                          key: 'contentblocks',
                          label: 'contentblock.contentblocks',
                          related: 'contentblocks { id, status, content_type, content, title, block_id }',
                          related_id: 'contentblocks',
                          idSingular: 'contentblock',
                          idSingularParent: 'block',
                          related_fields: 'id, content_type, content, title, block_id',
                          parent_id: 'block_id',
                          type: 'relations',
                          preselectValue: 'content_type',
                          display: {
                            edit: true,
                          },
                          col: 'col-12', // For all width of the page
                          props: {
                            table: true,
                            tableProps: {
                              rankDrag: true,
                              hideDefaultFooter: true,
                              disablePagination: true,
                              disableSort: true,
                            },
                            relatedFields: [
                              {
                                key: 'id',
                                type: 'number',
                              },
                              {
                                key: 'block_id',
                                type: 'number',
                              },
                              {
                                key: 'status',
                                type: 'status',
                                label: 'generic.state',
                                display: {
                                  table: true,
                                },
                              },
                              {
                                key: 'content_type',
                                label: 'block.content_type',
                                type: 'select',
                                display: {
                                  edit: true,
                                  add: true,
                                  table: true,
                                },
                                required: true,
                                items: [
                                  {
                                    title: 'block.html',
                                    id: 'html',
                                  },
                                  {
                                    title: 'block.image',
                                    id: 'image',
                                  },
                                  {
                                    title: 'block.video',
                                    id: 'video',
                                  },
                                  {
                                    title: 'block.button',
                                    id: 'button',
                                  },
                                ],
                              },
                              {
                                key: 'title',
                                label: 'page.internal_title',
                                type: 'text',
                                required: true,
                                international: true,
                                display: {
                                  edit: ['image', 'video', 'html', 'button'],
                                  add: ['image', 'video', 'html', 'button'],
                                  table: true,
                                }
                              },
                              {
                                key: 'content',
                                label: 'block.content',
                                type: 'htmltexteditor',
                                international: true,
                                required: true,
                                toolbar: // If the field is a WYSIWYG, we can override the default toolbar options
                                  `undo redo | formatselect | bold italic underline |
                                  bullist numlist | link | removeformat | code`, // Default toolbar values
                                display: {
                                  edit: ['html'],
                                  add: ['html'],
                                  table: true,
                                },
                              },
                              {
                                key: 'content',
                                label: 'block.content',
                                type: 'text',
                                international: true,
                                required: true,
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                              },
                              {
                                key: 'image',
                                label: 'generic.image',
                                type: 'media',
                                groupkey: 'main',
                                related: 'image { id, filename, file, type, dimensions, weight }',
                                listing_attribute: 'file',
                                listing_attributes: 'id,file',
                                display: {
                                  edit: ['image'],
                                  add: ['image'],
                                },
                                props: {
                                  typeFilter: ['image/%']
                                },
                              },
                              {
                                key: 'video',
                                label: 'generic.video',
                                type: 'media',
                                groupkey: 'main',
                                related: 'video { id, filename, file, type, dimensions, weight }',
                                listing_attribute: 'file',
                                listing_attributes: 'id,file',
                                display: {
                                  edit: ['video'],
                                  add: ['video'],
                                },
                                props: {
                                  typeFilter: ['video/%']
                                },
                              },
                              {
                                key: 'bk_color',
                                type: 'colorpicker',
                                label: 'block.bk_color',
                                display: {
                                  edit: ['html'],
                                  add: ['html'],
                                },
                              },
                              {
                                key: 'bk_color',
                                type: 'colorpicker',
                                label: 'block.bk_color',
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                                props: {
                                  defaultValue: '#D44C4FFF',
                                },
                              },
                              {
                                key: 'border_color',
                                type: 'colorpicker',
                                label: 'block.border_color',
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                                props: {
                                  defaultValue: '#D44C4FFF',
                                },
                              },
                              {
                                key: 'content_color',
                                type: 'colorpicker',
                                label: 'block.text_color',
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                                props: {
                                  defaultValue: '#FFFFFFFF',
                                },
                              },
                              {
                                key: 'content_color',
                                type: 'colorpicker',
                                label: 'block.text_color',
                                display: {
                                  edit: ['html'],
                                  add: ['html'],
                                },
                                props: {
                                  defaultValue: '#000000FF',
                                },
                              },
                              {
                                key: 'content_align',
                                type: 'btngroup',
                                label: 'block.text_align',
                                display: {
                                  edit: ['html', 'button'],
                                  add: ['html', 'button'],
                                },
                                props: {
                                  defaultValue: 0,
                                  columns: 3,
                                  icons: [
                                    'format_align_left',
                                    'format_align_justify',
                                    'format_align_right',
                                  ],
                                },
                              },
                              {
                                key: 'href',
                                type: 'text',
                                label: 'block.href',
                                international: true,
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                                props: {
                                  onFocusDefaultValue: 'http://',
                                  rules: [
                                    value => value === null || value === '' || (value && value.length <= 300) || 'errors.max_300',
                                    value => {
                                      const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
                                      return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
                                    },
                                  ]
                                },
                              },
                              {
                                key: 'targetblank',
                                type: 'boolean',
                                label: 'block.targetblank',
                                display: {
                                  edit: ['button'],
                                  add: ['button'],
                                },
                              },
                            ],
                            actions: {
                              editRows: true,
                              deleteRows: true,
                              addRows: true,
                            },
                          },
                        },
                      ],
                      actions: {
                        addRows: false,
                      }
                    },
                  ]
                },
              ],
            },
          ],
          actions: {
            addRows: true,
            editRows: true,
            deleteRows: true,
          }
        }
      },
      {
        key: 'created_since',
        groupkey: 'main',
        label: 'generic.created_since',
        type: 'text',
        sortClause: 'created_at',
        display: {
          listing: true,
        },
        listingStyle: {
          columnWidth: 95,
        },
      },
    ]
  };
