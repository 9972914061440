export default {
  product: 'Produit',
  products: 'Produits',
  verbose_product: 'un produit',
  code: 'Code',
  meta_description: 'Description meta',
  image_rollover: 'Survol',
  related_products: 'Produits reliés',
  configurator: 'Configurateur',
  documents: 'Documents',
  messages: {
    product_not_found: 'Produit non trouvé',
  },
};
