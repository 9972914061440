export default {
  article: 'Article',
  articles: 'Articles',
  verbose_article: 'un article',
  related_articles: 'Articles reliés',
  content: 'Contenu',
  news: 'Nouvelles',
  event_date: 'Date d\'évènement',
  published_at: 'Date de publication',
  messages: {
    article_not_found: 'Article non trouvé',
  },
};
