export { default as loadCrud } from '@ax2/xms-crud-module';

import usersConfig from './users.config';
import rolesConfig from './roles.config';
import articlesConfig from './articles.config';
import articletypesConfig from './articletypes.config';
import awardsConfig from './awards.config';
import eventsConfig from './events.config';
import pagesConfig from './pages.config';
import participantsConfig from './participants.config';
import participanttypesConfig from './participanttypes.config';
import projectsConfig from './projects.config';
import productsConfig from './products.config';
import productsectionsConfig from './productsections.config';
import categoriesConfig from './categories.config';
import categorytypesConfig from './categorytypes.config';
import specstypesConfig from './specstypes.config';
import specsvaluesConfig from './specsvalues.config';
import rulesConfig from './rules.config';
import mediacategoriesConfig from './mediacategories.config';

export default {
  contentLang: ['en', 'fr'],
  contentLangDefault: 'en',
  types: [
    articlesConfig,
    articletypesConfig,
    awardsConfig,
    eventsConfig,
    pagesConfig,
    participantsConfig,
    participanttypesConfig,
    projectsConfig,
    productsConfig,
    productsectionsConfig,
    categorytypesConfig,
    categoriesConfig,
    specstypesConfig,
    specsvaluesConfig,
    rulesConfig,
    usersConfig,
    rolesConfig,
    mediacategoriesConfig,
  ],
};
