export default {
  id: 'rules',
  idSingular: 'rule',
  saveable: true,
  typeName: 'rule',
  label: 'rule.rules',
  label_details: 'rule.rule',
  verbose_label_singular: 'rule.verbose_rule',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'rule_product',
      type: 'select',
      whereClause: 'left_code(||)right_code[like]',
      label: 'rule.select_product',
    },
    {
      id: 'rule_productsection',
      type: 'select',
      whereClause: 'left_code(||)right_code[like]',
      label: 'rule.select_section',
    },
    {
      id: 'rule_productspecstype',
      type: 'select',
      whereClause: 'left_code(||)right_code[like]',
      label: 'rule.select_specstype',
    },
    {
      id: 'rule_productspecsvalue',
      type: 'select',
      whereClause: 'left_code(||)right_code[like]',
      label: 'rule.select_specsvalue',
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'left_code',
      groupkey: 'main',
      label: 'rule.left_code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
    },
    {
      key: 'symbol',
      groupkey: 'main',
      label: 'rule.symbol',
      type: 'select',
      itemText: 'text',
      itemValue: 'value',
      items: [
        {
          value: 'range',
          text: 'rule.range',
        },
        {
          value: 'mandatory',
          text: 'rule.mandatory',
        },
        {
          value: 'implies_not',
          text: 'rule.implies_not',
        },
      ],
      required: true,
      display: {
        edit: true,
        listing: true,
        add: true,
      },
    },
    {
      key: 'right_code',
      groupkey: 'main',
      label: 'rule.right_code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'product_configuration',
      groupkey: 'main',
      label: 'rule.product_configuration',
      type: 'display',
      col: 'col-12',
      display: {
        add: true,
      },
      props: {
        searchLabel: 'rule.search_specification',
        query: `
          query product ($id: Int!) {
            displayField: product(id: $id) {
              id
              code
              configuration
            }
          }
        `,
        search: true,
        treeview: true,
        fetchParams: (ctx) => { return { id: parseInt(ctx.$route.params.id) }; },
        value: 'configuration',
        skipRule: (ctx) => { return ctx.$route.name.match(/.*-editItem/) === null; },
      }
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
