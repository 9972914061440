export default {
  id: 'specstypes',
  idSingular: 'specstype',
  saveable: true,
  typeName: 'specstype',
  label: 'specstype.specstypes',
  label_details: 'specstype.specstype',
  verbose_label_singular: 'specstype.verbose_specstype',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'options',
      label: 'specstype.options',
      hashLink: 'options',
      panel: true,
    },
    {
      key: 'display_options',
      label: 'specstype.display_options',
      hashLink: 'display-options',
      panel: true,
    },
    {
      key: 'specsvalues',
      label: 'specsvalue.specsvalues',
      hashLink: 'specsvalues',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'categorytypes',
      type: 'select',
      whereClause: 'type_id',
      label: 'categorytype.select_categorytypes',
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'main',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'internal_notes',
      groupkey: 'main',
      label: 'generic.internal_notes',
      type: 'textarea',
      display: {
        edit: true,
        add: true,
      },
      search: true,
    },
    {
      key: 'is_multiple',
      groupkey: 'options',
      label: 'specstype.is_multiple',
      type: 'boolean',
      display: {
        edit: true,
        add: true,
      },
    },
    {
      key: 'is_optional',
      groupkey: 'options',
      label: 'specstype.is_optional',
      type: 'boolean',
      display: {
        edit: true,
        add: true,
      },
    },
    {
      key: 'display_configurator',
      groupkey: 'display_options',
      label: 'specstype.display_configurator',
      type: 'boolean',
      display: {
        edit: true,
        add: true,
      },
      props: {
        defaultValue: true,
      },
    },
    {
      key: 'specsvalues',
      groupkey: 'specsvalues',
      label: 'specsvalue.specsvalues',
      type: 'relations',
      related: 'specsvalues { id, title, code }',
      related_id: 'specsvalues',
      idSingular: 'specsvalue',
      related_fields: 'id, title, code',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
          {
            key: 'code',
            label: 'generic.code',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
          addRelation: true,
        },
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
