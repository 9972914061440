export default {
  product: 'Product',
  products: 'Products',
  verbose_product: 'a product',
  code: 'Code',
  meta_description: 'Meta Description',
  image_rollover: 'Rollover',
  related_products: 'Related products',
  configurator: 'Configurator',
  documents: 'Documents',
  messages: {
    product_not_found: 'Product not found',
  },
};
